import React from 'react'
import { ContMap } from '../styles/Address';


const SecMap = (props) => {

    const {
        iframeSource = '<iframe id="containerIframe"  style="border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.4284581182624!2d-74.06079552412561!3d4.695379041701821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9ac6166dbcc1%3A0x3703e3805e678a21!2sCra.%2047%20%23106a-51%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1ses-419!2spe!4v1706127788315!5m2!1ses-419!2spe"></iframe>'
    } = props;


    return (
        <>
            <ContMap>
                <div
                    dangerouslySetInnerHTML={{ __html: iframeSource }}>
                </div>
            </ContMap>
        </>
    )
}

export default SecMap