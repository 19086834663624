import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ContacBar from '../components/ContacBar'
import { data } from '../data/data'
import { ContPagesEmpresa } from '../styles/NavEmpresa'
import { ConNosotros, MenuMobileNos, MobileTitle, } from '../styles/Nosotros'
// Icons
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavServicios from '../components/NavServicios'
import BannerServicios from '../components/BannerServicios'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ContCard1, ContCard2, ContImg1, ContText1, TitleDocuments } from '../styles/Gestion'
import { CardServicios, ContCardServicios, ContImage, ContLink } from '../styles/CardsServicios'
import { Helmet } from 'react-helmet'
import { CardPol, ContCardPol } from '../styles/Politicas'
// documents
import calidad from '../documents/POLITICA Y OBJETIVOS DE CALIDAD.pdf';
import manual from '../documents/MANUAL DE MARCA ITICCOL.pdf';



const Producto = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    //Get data
    const [datos] = data;
    const { servicios: { dropDownMenu: { producto: { helmet, name, cardImg, subTitle2, cards } } } } = datos


    // extract values of cards
    const card = Object.values(cards);

    //.......................................................
    // extract values of dropDownMenu
    const items = Object.values(datos.servicios.dropDownMenu);
    // go through the array and divide it in two
    const indice = items.findIndex((item) => item.name === name)

    const itemsFind0 = items.slice(0, indice)
    const itemsUrlUp = Object.values(itemsFind0)
    // console.log(itemsUrlUp)

    const itemsFind1 = items.slice(indice + 1)
    const itemsUrlDown = Object.values(itemsFind1)
    // console.log(itemsUrl)
    //.......................................................




    return (
        <>
            <Helmet>
                <title>{helmet} – ITIC Colombia</title>
                <meta name="description" content=" Nuestros procesos de certificación son rigurosos y ágiles. Nos esforzamos por asegurar que todos los productos que certificamos cumplan con los más altos estándares de calidad y seguridad."/>
                <meta name="keywords" content=" Certificación de producto, Retie, Retilap, Retiq, Pilas, Reglamento barras corrugadas, reglamento refirgeración Costa Rica, Reglamento de seguridad gasodomésticos, TCA ENEl, Normas técnicas, Servicios en energía solar, paneles solares"/>
                <link rel="canonical" href=" https://iticco.com/servicios/producto" />
            </Helmet>
            <h1>CERTIFICACIÓN DE PRODUCTO</h1>
            <BannerServicios />
            <ContPagesEmpresa>
                <div className='ContSectionEm'>
                    <NavServicios />
                    <ConNosotros>

                        <MenuMobileNos>
                            {
                                itemsUrlUp.map((item) =>
                                    <Link to={item.url} key={item.name}><div>{item.name}<ArrowDropDownIcon /></div></Link>
                                )
                            }
                        </MenuMobileNos>

                        <MobileTitle>
                            <p>{name.split('\n').map((line, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                )
                            })}<ArrowDropDownIcon /></p>
                        </MobileTitle>

                        <ContCard1>
                            <ContCard2>
                                <ContImg1>
                                    <img src={require(`../images/${cardImg}`)} alt={cardImg} />
                                </ContImg1>
                                <ContText1>
                                    <p className='title_section'>{name}</p>
                                    <p className='paragraph_section'>{subTitle2}</p>
                                </ContText1>
                            </ContCard2>
                        </ContCard1>

                        <ContCardServicios>
                            {
                                card.map((item) =>
                                    <CardServicios key={item.id}>

                                        <ContImage>
                                            <img src={require(`../images/${item.img}`)} alt={item.text1} />
                                        </ContImage>

                                        <ContLink to={item.id}>
                                            <div>
                                                <p>
                                                    {item.text1.split('\n').map((line, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                {line}
                                                                <br />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </p>
                                                <AddCircleIcon />
                                            </div>
                                        </ContLink>
                                    </CardServicios>
                                )
                            }
                        </ContCardServicios>

                        <TitleDocuments className='title_section'>Documentos de Interés</TitleDocuments>
                        <ContCardPol>
                            <CardPol target='_blank' to={calidad} rel="noopener noreferrer">
                                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                                <div><div className='text_card_poli'><p>Política de la calidad (servicios industriales)</p></div></div>
                            </CardPol>
                            <CardPol target='_blank' to={manual} rel="noopener noreferrer">
                                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                                <div><div className='text_card_poli'><p>Manual de Marca</p></div></div>
                            </CardPol>
                        </ContCardPol>

                        <MenuMobileNos>
                            {
                                itemsUrlDown.map((item) =>
                                    <Link to={item.url} key={item.name}><div>
                                        <p>{item.name.split('\n').map((line, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            )
                                        })}</p>
                                        <ArrowDropDownIcon /></div></Link>
                                )
                            }
                        </MenuMobileNos>

                    </ConNosotros>
                </div>
                <ContacBar />
            </ContPagesEmpresa>
        </>
    )
}

export default Producto