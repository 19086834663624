import React from 'react'
import BannerEmpresa from '../components/BannerEmpresa'
import NavEmpresa from '../components/NavEmpresa'
import { data } from '../data/data'
import { ContPagesEmpresa } from '../styles/NavEmpresa'
import { ConNosotros, MenuMobileNos, MobileTitle } from '../styles/Nosotros'
import { CardPol, ContCardPol } from '../styles/Politicas'
import { TitleSectionPro } from '../styles/Proyectos'
import ContacBar from '../components/ContacBar'
import { Link } from 'react-router-dom'
// Icons
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// Documents
import datos_personales from '../documents/POLITICA DE PRIVACIDAD DE PROTECCION Y TRATAMIENTO DE DATOS PERSONALES.pdf';
import calidad from '../documents/POLITICA Y OBJETIVOS DE CALIDAD.pdf';
import prev_acoso from '../documents/POLITICA DE PREVENCION ACOSO LABORAL.pdf';
import negocio from '../documents/CONDICIONES GENERALES DEL NEGOCIO.pdf';
import certificacion_personas from '../documents/REGLAMENTO CERTIFICACION DE PERSONAS.pdf';
import etica from '../documents/COMPROMISO DE CONFIDENCIALIDAD ETICA Y CONDUCTA.pdf';
import navegacion from '../documents/POLITICA DE DATOS DE NAVEGACION.pdf';
import tabaco from '../documents/POLITICA SOBRE NO USO DE ALCOHOL, DROGAS Y TABACO.pdf';
import seg_vial from '../documents/POLITICA DE SEGURIDAD VIAL.pdf';
import reg_trabajo from '../documents/REGLAMENTO INTERNO DE TRABAJO.pdf';
import certificacion from '../documents/REGLAS DE CERTIFICACION.pdf';
import certificacion_personas2 from '../documents/POLITICA DE SEGURIDAD PROCESO DE CERTIFICACION DE PERSONAS.pdf';
import alcance from '../documents/POLITICA DE SUSPENSION, RETIRO O REDUCCION DEL ALCANCE DE CERTIFICACION PERSONAS.pdf';
import info_general from '../documents/INFORMACION GENERAL CERTIFICACION DE PERSONAS.pdf';
import gestion_salud from '../documents/POLITICA DE GESTION INTEGRAL SALUD, SEGURIDAD Y AMBIENTE HSE.pdf';
import conflictos from '../documents/DIRECTIVA PARA EVITAR CONFLICTOS DE INTERESES Y CORRUPCION.pdf';
import independiencia from '../documents/POLITICA DE IMPARCIALIDAD INDEPENDENCIA Y CONFIDENCIALIDAD.pdf';
import quejas_apelaciones from '../documents/PROCEDIMIENTO DE CONSULTAS, DENUNCIAS Y PQAR\'s.pdf';
import formato_quejas from '../documents/FORMATO DE QUEJAS Y APELACIONES.pdf';
import gest_integral from '../documents/POLITICA DE GESTION INTEGRAL SALUD, SEGURIDAD Y AMBIENTE HSE.pdf';
import reg_gestion from '../documents/REGLAMENTO DE CERTIFICACION DE SISTEMAS DE GESTION.pdf';

import { Helmet } from 'react-helmet'


const Politicas = () => {

  //get data
  const [datos] = data;
  const { empresa: { dropDownMenu: { politicas: { helmet, name, cards } } } } = datos;
  const card = Object.values(cards);
  // console.log(card)

  //.......................................................
  // extract values of dropDownMenu
  const items = Object.values(datos.empresa.dropDownMenu);
  // go through the array and divide it in two
  const indice = items.findIndex((item) => item.name === name)

  const itemsFind0 = items.slice(0, indice)
  const itemsUrlUp = Object.values(itemsFind0)
  // console.log(itemsUrlUp)

  const itemsFind1 = items.slice(indice + 1)
  const itemsUrlDown = Object.values(itemsFind1)
  // console.log(itemsUrl)
  //.......................................................


  return (
    <>
      <Helmet>
        <title>{helmet} – ITIC Colombia</title>
      </Helmet>
      <h1>POLÍTICAS, CÓDIGOS Y REGLAMENTOS</h1>
      <BannerEmpresa />
      <ContPagesEmpresa>
        <div className='ContSectionEm'>
          <NavEmpresa />

          <ConNosotros>

            <MenuMobileNos>
              {
                itemsUrlUp.map((item) =>
                  <Link to={item.url} key={item.name}><div>{item.name}<ArrowDropDownIcon /></div></Link>
                )
              }
            </MenuMobileNos>

            <MobileTitle>
              <p>{name.split('\n').map((line, i) => {
                return (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                )
              })}<ArrowDropDownIcon /></p>
            </MobileTitle>

            <TitleSectionPro><p>{name}</p></TitleSectionPro>

            <ContCardPol>
              <CardPol target='_blank' to={datos_personales} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de protección de datos personales</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={calidad} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de la calidad (servicios industriales)</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={prev_acoso } rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política De Prevención De Acoso Laboral</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={negocio} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Condiciones generales del negocio</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={certificacion_personas} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Reglamento certificación de personas</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={etica} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Compromiso de confidencialidad ética y conducta</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={navegacion} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de datos de navegación</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={tabaco} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política sobre no uso de alcohol, drogas y tabaco</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={reg_trabajo} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Reglamento interno de trabajo Itic Colombia</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={seg_vial} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de seguridad vial</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={certificacion} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Reglas de certificación</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={certificacion_personas2} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de seguridad proceso de certificación de personas</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={info_general} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Información general (certificación de personas)</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={alcance} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de suspensión, retiro o reducción del alcance de certificación personas</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={gestion_salud} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Política de gestión integral salud, seguridad y ambiente (HSE)</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={conflictos} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>Directiva para evitar conflictos de intereses y corrupción</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={independiencia} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>
                  Política de imparcialidad independencia y confidencialidad</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={quejas_apelaciones} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>
                  Procedimiento de consultas, denuncias y PQAR’S</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={formato_quejas} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>
                Formato De Quejas Y Apelaciones</p></div></div>
              </CardPol>
              <CardPol target='_blank' to={reg_gestion} rel="noopener noreferrer">
                <div className='text_img_poli'><FileOpenOutlinedIcon /></div>
                <div><div className='text_card_poli'><p>
                Reglamento de Certificación de Sistemas de Gestión</p></div></div>
              </CardPol>

            </ContCardPol>

            <MenuMobileNos>
              {
                itemsUrlDown.map((item) =>
                  <Link to={item.url} key={item.name}><div>
                    <p>{item.name.split('\n').map((line, i) => {
                      return (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      )
                    })}</p>
                    <ArrowDropDownIcon /></div></Link>
                )
              }
            </MenuMobileNos>

          </ConNosotros>
        </div>
        <ContacBar />
      </ContPagesEmpresa>
    </>
  )
}

export default Politicas